import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const bookingId = 'bookingId';
const PaymentFailPage = (props) => {
  const history = useHistory();
  const [bkId, setBkId] = useState('');
    useEffect(() => {
        setBkId(localStorage.getItem(bookingId));
    }, []);

    const handleRetry = () => {
      history.push({ pathname: `mobile-payment/${bkId}` });
    };
  return (

    <div key="Payment" className="paymentBody">
      <div className="App-payment paymentForm">
        <div className="failSec">
          <h6>Payment Failed!</h6>
          <button  className="btn-primary" onClick={handleRetry}>Try again</button>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailPage;
