import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import NewPaymentWaitingContainer from "./NewPaymentWaitingContainer";
import PaymentContainer from "./Payment";
import PaymentSuccessPage from "./PaymentSuccessPage";
import PaymentFailPage from "./PaymentFailPage";


export default function App() {
  return (
    <Router>
          <Route path="/mobile-payment/:id" component ={PaymentContainer} />
          <Route path="/payment-verifying" component={NewPaymentWaitingContainer} />
          <Route path="/payment-success" component={PaymentSuccessPage} />
          <Route path="/payment-fail" component={PaymentFailPage} />
    </Router>
  );
}
