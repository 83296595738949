import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const bookingId = 'bookingId';
const PaymentSuccessPage = (props) => {
  const history = useHistory();
  const [paymentInfo, setPaymentInfo] = useState({});
  const [bkId, setBkId] = useState('');
    useEffect(() => {
      if (props.location.search) {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const code = params.get('response_code'); // bar
        if (code === '22222') {
          setPaymentInfo({ responseMsg: 'payment failed' });
          setBkId(localStorage.getItem(bookingId));
          // history.push({ pathname: 'payment-fail' });
        } else {
          localStorage.removeItem(bookingId);
        }
      } else {
        localStorage.removeItem(bookingId); 
      }
    }, [props.location]);
    console.log(paymentInfo);

    const handleRetry = () => {
      history.push({ pathname: `mobile-payment/${bkId}` });
    };
  return (

    <div key="Payment" className="paymentBody">
      <div className="App-payment paymentForm">
        <div className="failSec">
          {!_.isEmpty(paymentInfo) ? <h6>Payment Failed!</h6> : <h6>Payment Success</h6>}
          {!_.isEmpty(paymentInfo) && <button  className="btn-primary" onClick={handleRetry}>Try again</button>}
          {/* <button className="btn-primary">Try again</button> */}
        </div>
      </div>
    </div>
  );
};
// function mapStateToProps(state) {
//   return {
//     goBookingId: state.GoPaymentReducer.goBkingId.data,
//   };
// }

export default PaymentSuccessPage;
