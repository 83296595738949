// import 'bootstrap/dist/css/bootstrap.min.css';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';

import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC,
} from '../utils/CardValidations';

export const bookingId = 'bookingId';

const initialState = {
  totalSum: 0,
  currency: 'QAR',
  errorMsgs: [],
  bookingStatus: 'payment-done',
  email: '',
  errorExists: false,
  isLoading: false,
  name: '',
  number: '',
  expiry: '',
  cvc: '',
  focused: '',
  issuer: '',
  formData: '',
  bookingRes: 'success',
  bookingRefId: '',
  bookingData: {},
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'CLEAR':
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};

const TessPaymentContainer = ({ bookingData, couponData, cardType, paytype }) => {
  console.log('bookingData====', bookingData);
  console.log('bookingData====', cardType);
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errors, setErrors] = useState({});
  const [invalid, setInvalid] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(bookingData)) {
      console.log(bookingData);
      dispatch({ type: 'bookingRefId', payload: bookingData.bkngid });
      dispatch({ type: 'email', payload: bookingData.email });
      dispatch({ type: 'totalSum', payload: bookingData.prc });
      dispatch({ type: 'bookingData', payload: bookingData });
    }
  }, [bookingData]);
  const paymentRetrieve = async () => {
    dispatch({ type: 'isLoading', payload: true });
    const obj = {
      card: state.number.replace(/ +/g, ''),
      cvv2: state.cvc,
      expYear: state.expiry.split('/')[1],
      expMonth: state.expiry.split('/')[0],
      member: state.name,
      currencyCode: 'QAR',
      address: '',
      city: '',
      statecode: '',
      zip: 'MOBILE',
      CountryCode: 'QA',
      email: state.email,
      amount: Number(state.totalSum),
    };
    console.log(obj.amount);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj),
    };
    try {
      const fetchResponse = await fetch(
         `http://3.137.57.104:3007/api/vista_money/purchase`,
        requestOptions
      );
      const data = await fetchResponse.json();
      console.log(data);
      if (data.targetUrl.length > 0) {
        const url = data.targetUrl + data.payId;
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.click();
      }
    } catch (e) {
      history.push('/payment-fail');
      return e;
    }
  };

  const handleName = (e) => {
    if (state.name === '') {
      setErrors({ name: 'Please enter name as it appears on your card.' });
    }
  };

  const handleCardNumber = (e) => {
    const re16digit = /^\d{14,22}$/;
    console.log(e.target.value);
    if (state.number === '') {
      setErrors({ number: 'Please enter the card number.' });
    } else if (!re16digit.test(e.target.value.replace(/ +/g, ''))) {
      setErrors({ number: 'The card number you entered is invalid.' });
    }
  };

  const handleExpiry = (e) => {
    let dateValdate = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
    if (state.expiry === '') {
      setErrors({ expiry: 'Please enter your card expiry date.' });
    } else if (!dateValdate.test(state.expiry.replace(/ +/g, ''))) {
      setErrors({
        expiry: 'The expiry date entered is incorrect. Please re-enter.',
      });
    }
  };

  const handleCVV = (e) => {
    let cvcValidate = /^\d{3,5}$/;
    if (state.cvc === '') {
      setErrors({ cvc: 'Please enter your card CVV number.' });
    } else if (!cvcValidate.test(state.cvc.replace(/ +/g, ''))) {
      setErrors({
        cvc: 'The CVV number you entered is incorrect. Please reconfirm.',
      });
    }
  };

  const paymentRetrieveDebitCard = async () => {
    console.log('paymentRetrieveDebitCard', bookingData);
    const obj = {
      currencyCode: 'QAR',
      countryCode: 'QA',
      email: bookingData.email,
      amount: bookingData.prc,
      bkref: bookingData.bkngid,
      zip: "MOBILE"
    };
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(obj),
    };
    try {
      const fetchResponse = await fetch(
        `http://3.137.57.104:3007/api/vista_money/purchase-debit`,
        requestOptions
      );  
      console.log('fetchResponse', fetchResponse);
      
      const data = await fetchResponse.json();
      console.log(data);
      if (data.targetUrl.length > 0) {
        const url = data.targetUrl + data.payId;
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.click();
      }
    } catch (e) {
      history.push('/payment-fail');
      return e;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(state);
    if (cardType !== 'debitCard') {
      let re16digit = /^\d{14,22}$/;
      let dateValdate = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
      let cvcValidate = /^\d{3,5}$/;
      if (!state.name) {
        setErrors({ name: 'Please enter name as it appears on your card.' });
        dispatch({ type: 'isLoading', payload: false });
      } else if (!state.number) {
        setErrors({ number: 'Please enter the card number.' });
        dispatch({ type: 'isLoading', payload: false });
      } else if (!re16digit.test(state.number.replace(/ +/g, ''))) {
        setErrors({ number: 'The card number you entered is invalid.' });
        dispatch({ type: 'isLoading', payload: false });
      } else if (!state.expiry) {
        setErrors({ expiry: 'Please enter your card expiry date.' });
        dispatch({ type: 'isLoading', payload: false });
      } else if (!dateValdate.test(state.expiry.replace(/ +/g, ''))) {
        setErrors({
          expiry: 'The expiry date entered is incorrect. Please re-enter.',
        });
        dispatch({ type: 'isLoading', payload: false });
      } else if (!state.cvc) {
        setErrors({ cvc: 'Please enter your card CVV number.' });
        dispatch({ type: 'isLoading', payload: false });
      } else if (!cvcValidate.test(state.cvc.replace(/ +/g, ''))) {
        setErrors({
          cvc: 'The CVV number you entered is incorrect. Please reconfirm.',
        });
        dispatch({ type: 'isLoading', payload: false });
      } else {
        localStorage.setItem(bookingId, bookingData.bkngid);
        paymentRetrieve();
      }
    } else {
      localStorage.setItem(bookingId, bookingData.bkngid);
      paymentRetrieveDebitCard();
    }
  };
  const handleInputChange = ({ target }) => {
    setErrors({});

    if (
      document.querySelector('.holderName').value !== '' &&
      document.querySelector('.cardNumber').value.length >= 16 &&
      document.querySelector('.expiryDate').value.length === 7 &&
      document.querySelector('.cvvNumber').value.length === 3
    ) {
      setInvalid(false);
    } else {
      setInvalid(true);
    }

    if (target.name === 'number') {
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
    }

    dispatch({ type: [target.name], payload: target.value });
    // Crad type validation
    if (target.name === 'number') {
      const x = target.value;
      const z = x.substring(0, 2);
      console.log(z);
      const numberFormated = target.value.replace(/\D/g, '');
      var patterns = {
        VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MASTER: /^5[1-5][0-9]{14}$/,
        AMEX: /^3[47][0-9]{13}$/,
        ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
        DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        DISCOVERY: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        MAESTRO:
          /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        DANKORT: /^(5019)\d+$/,
        INTERPAYMENT: /^(636)\d+$/,
        UNIONPAY: /^(62|88)\d+$/,
      };
      for (var key in patterns) {
        if (patterns[key].test(numberFormated)) {
          dispatch({ type: 'issuer', payload: key });
          return key;
        }
      }
    }
  };
  const handleInputFocus = ({ target }) => {
    dispatch({ type: 'focused', payload: target.name });
  };
  return (
    <form>
      {paytype === 'debitcard' ?  
        <div className='cashPaybutton'>
          <button
            onClick={handleSubmit}
            className={
              'progress-bar  ' +
              (state.isLoading
                ? ' progress-bar-striped progress-bar-animated'
                : '') 
             
            }
          >
            {state.isLoading ? (
              'Processing...'
            ) : (
              <span>
                Pay {state.currency} {state.totalSum}
              </span>
            )}{' '}
          </button>
        </div>
      : <div> <Form.Row autoComplete='off'>
        <div className='col-12'>
          <Form.Group as={Col} className={errors.number && 'invalid'}>
            <Form.Label>Card number</Form.Label>

            <input
              type='tel'
              name='number'
              className='form-control cardNumber'
              placeholder='Enter Card Number'
              pattern='[\d| ]{16,22}'
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              value={state.number}
              onBlur={handleCardNumber}
            />
            <span className={'icon cardPhoto ' + state.issuer}></span>
            {errors.number && <p className='errorMsg'>{errors.number}</p>}
          </Form.Group>
        </div>
        <div className='row'>
          <div className='col-6'>
            <Form.Group as={Col} className={errors.expiry && 'invalid'}>
              <Form.Label>Expiry date</Form.Label>
              <input
                type='tel'
                name='expiry'
                className='form-control expiryDate'
                placeholder='MM/YYYY'
                pattern='\d\d/\d\d\d\d'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={state.expiry}
                onBlur={handleExpiry}
              />
              {errors.expiry && <p className='errorMsg'>{errors.expiry}</p>}
            </Form.Group>
          </div>
          <div className='col-6'>
            <Form.Group as={Col} className={errors.cvc && 'invalid'}>
              <Form.Label>
                CVC <span className='tiptext'>What's this?</span>
                <div className='cvc-tooltip'>
                  With a Visa or MasterCard the CVV/CVC is a three-digit
                  security code printed on the back side of the card. On a
                  American Express card, the number is four digits shown on the
                  front of the card
                </div>
              </Form.Label>

              <input
                type='tel'
                name='cvc'
                className='form-control cvvNumber'
                placeholder='CVC'
                pattern='\d{3,4}'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                value={state.cvc}
                onBlur={handleCVV}
              />
              {errors.cvc && <p className='errorMsg'>{errors.cvc}</p>}
            </Form.Group>
          </div>
        </div>
        <div className='col-12'>
          <Form.Group as={Col} className={errors.name && 'invalid'}>
            <Form.Label>Holder Name</Form.Label>
            <input
              type='text'
              name='name'
              className='form-control holderName'
              placeholder='Name'
              // required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              autoComplete='new-password'
              value={state.name}
              onBlur={handleName}
            />
            {errors.name && <p className='errorMsg'>{errors.name}</p>}
          </Form.Group>
        </div>
        {/* <div className="col-12">
                <Form.Check type="checkbox" label="Save card for future purchases" />
              </div> */}
      </Form.Row>
      <input type='hidden' name='issuer' value={state.issuer} />
      {errors.serviceError && <p className='errorMsg'>{errors.serviceError}</p>}
      <div className='form-actions'>
        <button
          onClick={handleSubmit}
          className={
            'progress-bar  ' +
            (state.isLoading
              ? ' progress-bar-striped progress-bar-animated'
              : '') +
            (invalid ? 'invalid' : '')
          }
          disabled={state.isLoading ? true : false}
        >
          {state.isLoading ? (
            'Processing...'
          ) : (
            <span>
              Pay {state.currency} {state.totalSum}
            </span>
          )}{' '}
        </button>
      </div>
      </div>}
    </form>
  );
};

export default TessPaymentContainer;
