/* eslint-disable react-hooks/exhaustive-deps */
import { sha256 } from 'js-sha256';
import _ from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useHistory, useParams } from "react-router-dom";
import Cookies from 'universal-cookie';

import {
  formatCreditCardNumber,
  formatExpirationDate,
  formatCVC
} from './utils/CardValidations';
import TessPaymentContainer from './components/TessPaymentContainer' ;


const ACCESS_CODE = '1Pde5AbA6StSNIDbEDhP';
const MERCHANT_ID = '77dd22f9';
const REQUEST_PHRASE = '18hYbBoHqu.qv3354lU/Ap{!';
const PAYMENT_URL = 'https://sbcheckout.payfort.com/FortAPI/paymentPage';
const LANGUAGE = 'en';
const initialState = {
  totalSum: 0,
  currency: 'AED',
  isLoading: false,
  focused: '',
  issuer: '',
  mid: '',
  merchantRef1: '',
  card_holder_name: '',
  card_number: '',
  expiry_date: '',
  card_security_code: '',
  paymentType: '',
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'CLEAR':
      return {
        ...state,
      };
    default:
      return { ...state, [action.type]: action.payload };
  }
};
export const bookingId = 'bookingId';
const PaymentContainer = () => {
  let { id } = useParams();
  // console.log(id);
  const history = useHistory();
  const returnUrl = 'https://staging-api.shypv.com/v1.0/payment/token'
  const domainUrl = 'mpayments-stage.shypv.com/payment-verifying'
  const [state, dispatch] = useReducer(reducer, initialState);
  const [signature, setSignature] = useState();
  const [errors, setErrors] = useState({});
  const [validate, setValidate] = useState(true);
  const [holderError, setHolderError] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [showTabs, setShowTabs] = useState(false);
  const [cashType, setCashType] = useState('collect at pickup');
  const [isLoading, setIsLoading] = useState(false);
  const [payType, setPayType] = useState('card');
  const [bookingDetails, setBookingDetails] = useState({});
  const [hideCard, setHideCard] = useState(false);
  const [debitTab, setDebitTab] = useState(false);
  const [cashTab, setCashTab] = useState(true);
  // console.log(process.env.REACT_APP_ACCESS_CODE,process.env.REACT_APP_MERCHANT_ID,process.env.REACT_APP_REQUEST_PHRASE,process.env.REACT_APP_PAYMENT_URL,process.env.REACT_APP_LANGUAGE, returnUrl)
  const [domain, setDomain] = useState('UAE');
  const cookies = new Cookies();
  const tabClickCard = () => {
    setPayType('card');
    setDebitTab(false);
    setCashTab(true);
    setShowTabs(false);
    setErrors({});
    setValidate(true);
    if (showTabs) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
    dispatch({ type: 'paymentType', payload: 'card' });
  };
  const tabClickCash = () => {
    setErrors({});
    setCashTab(false);
    setShowTabs(true);
    setValidate(true);
    setDebitTab(false);
    setPayType('cash');
    if (showTabs) {
      setShowTabs(false);
    } else {
      setShowTabs(true);
    }
    dispatch({ type: 'paymentType', payload: 'cash' });
  };

  const tabDebitCard = () => {
    setPayType('debitCard');
    setDebitTab(true);
    setShowTabs(false);
    setCashTab(false);
  }

  const cpClick = () => {
    setCashType('collect at pickup');
  };
  const cdClick = () => {
    setCashType('collect at delivery');
  };

  const handlePay = () => {
    console.log(payType)
    setIsLoading(true);
    console.log(`/payment-success?paytyp=${state.paymentType}&ptyp=${cashType}`);
    history.push(`/payment-success?paytyp=${state.paymentType}&ptyp=${cashType}`);
  };

  const getMerchantRef = () => {
    const merchantRef = new Date().getTime();
    const merchantExtra = domainUrl;
    const merchantExtra1 = id;
    dispatch({ type: 'mid', payload: merchantRef });
    const sign = `${REQUEST_PHRASE}access_code=${ACCESS_CODE}language=${LANGUAGE}merchant_extra=${merchantExtra}merchant_extra1=${merchantExtra1}merchant_identifier=${MERCHANT_ID}merchant_reference=${merchantRef}return_url=${returnUrl}service_command=TOKENIZATION${REQUEST_PHRASE}`;
   //  console.log(sign);
    // console.log(domainUrl);
    setSignature(sha256(sign));
  };

  const handleSubmit = () => {
    setButtonDisable(true);
    localStorage.setItem(bookingId, id);
    //cookies.set('bookingId', id, { path: '/' });
  }

  useEffect(() => {
    dispatch({ type: 'card_holder_name', payload: '' });
    dispatch({ type: 'card_number', payload: '' });
    dispatch({ type: 'expiry_date', payload: '' });
    dispatch({ type: 'card_security_code', payload: '' });
    getMerchantRef();
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    console.log(urlParams.get('cash'));
    const cash = urlParams.get('cash');
    console.log(cash)
    if (urlParams.get('cash')) {
      setPayType('cash');
      setShowTabs(true);
      dispatch({ type: 'paymentType', payload: 'cash' });
    }
  }, []);
  console.log(state.paymentType);
  const fetchBookingDetails = async (id) => {
    const url = `https://staging-api.shypv.com/v1.0/task/booking_details?booking_id=${id}`
    await fetch(url).then((response) => response.json())
      .then(res => { return setBookingDetails(res.data) });
  };
  useEffect(() => {
    if (id) {
        localStorage.setItem(bookingId, id);
        //cookies.set('bookingId', id, { path: '/' });
      const isQatar = id.substring(0, 2);
      if (isQatar === 'QC') {
        setDomain('QA');
        setPayType('card');
      } else {
        setHideCard(false);
      }
      const fetchData = async () => {
        await fetchBookingDetails(id);
      };
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (!_.isEmpty(bookingDetails)) {
      dispatch({ type: 'currency', payload: bookingDetails.cur });
      dispatch({ type: 'totalSum', payload: bookingDetails.prc });
    }
  }, [bookingDetails]);
  const handleInputChange = ({ target }) => {

    setErrors({});
    setHolderError(false)

    if (document.querySelector('.holderName').value.replace(/ +/g, '') !== ''
      && document.querySelector('.cardNumber').value.replace(/ +/g, '').length >= 16
      && document.querySelector('.expiryDate').value.replace(/ +/g, '').length === 5
      && document.querySelector('.cvvNumber').value.length === 3) {
      setValidate(false);
    } else {
      setValidate(true);
    }
    console.log(target.className);
    if (target.id === 'card-number') {
      if (target.value === '') {
        setErrors({ card_number: 'Card Number is required' });
        setValidate(true);
      }
      target.value = formatCreditCardNumber(target.value);
    }
    if (target.id === 'our_expiry_date') {
      if (target.value === '') {
        setErrors({ expiry_date: 'Expire Date is required' });
        setValidate(true);
      }
      target.value = formatExpirationDate(target.value);
    }
    if (target.name === 'card_security_code') {
      if (target.value === '') {
        setErrors({ card_security_code: 'CVC is required' });
        setValidate(true);
      }
      target.value = formatCVC(target.value);
    }

    dispatch({ type: [target.name], payload: target.value });
    if (target.id === 'card-number') {
      target.value = formatCreditCardNumber(target.value);
      // const x = target.value;
      // const z = x.substring(0, 2);
      const numberFormated = target.value.replace(/\D/g, '');
      const patterns = {
        VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
        MASTER: /^5[1-5][0-9]{14}$/,
        AMEX: /^3[47][0-9]{13}$/,
        ELO: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        AURA: /^(5078\d{2})(\d{2})(\d{11})$/,
        JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
        DINERS: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        DISCOVERY: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        HIPERCARD: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
        ELECTRON: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        MAESTRO: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        DANKORT: /^(5019)\d+$/,
        INTERPAYMENT: /^(636)\d+$/,
        UNIONPAY: /^(62|88)\d+$/,
      };
      for (const key in patterns) {
        if (patterns[key].test(numberFormated)) {
          dispatch({ type: 'issuer', payload: key });
          return key;
        } else {
          dispatch({ type: 'issuer', payload: '' });
        }
      }
    }

  };


  const handleCardNumber = (e) => {
    const re16digit = /^\d{16,22}$/;
    document.getElementById('cardNumber').value = formatCreditCardNumber(e.target.value).replace(/ +/g, '');
    if (e.target.value === '') {
      setErrors({ card_number: 'Card Number is required' });
      setValidate(true);
    } else if (!re16digit.test(e.target.value.replace(/ +/g, ''))) {
      setErrors({ card_number: 'Card Number is invalid' });
      setValidate(true);
    }
  };

  const handleExpiry = (e) => {
    let valueExp = e.target.value
    const dateValue = formatCreditCardNumber(valueExp).split('/');
    const str = dateValue[0];
    document.getElementById('expiryDate').value = str.substr(2, 4) + str.substr(0, 2);
    const dateValdate = /^(0[1-9]|1[0-2]|[1-9])\/(2[4-9]|[2-9][1-9]|20[1-9][1-9])$/;
    if (valueExp === '') {
      setErrors({ expiry_date: 'Expire Date is required' });
      setValidate(true);
    } else if (valueExp.replace(/ +/g, '').length < 5) {
      setErrors({ expiry_date: 'Expire Date is invalid' });
      setValidate(true);
    } else if (!dateValdate.test(valueExp.replace(/ +/g, ''))) {
      setErrors({ expiry_date: 'Expire Date is invalid' });
      setValidate(true);
    }
  }

  const handleCvv = (e) => {
    if (e.target.value === '') {
      setErrors({ card_security_code: 'CVC Number is required' });
      setValidate(true);
    } else if (e.target.value.length < 3) {
      setErrors({ card_security_code: 'CVC Number is invalid' });
      setValidate(true);
    }
  }

  console.log(state.paymentType);

  return (
    <div key="Payment" className="paymentBody">
      <div className="App-payment paymentForm">
        {hideCard ? <ul className="tabHeader">
          <li className={showTabs ? 'active' : ''} onClick={tabClickCash}>Cash</li>
        </ul> :
        <ul className="tabHeader">
        <li className={cashTab ? 'active' : ''} onClick={tabClickCard}>{domain === 'UAE' ? 'Credit/ Debit Card' : 'Credit Card'}</li>
        {domain !== 'UAE' && <li className={debitTab ? 'active' : ''} onClick={tabDebitCard}>Debit Card</li>}
        <li className={showTabs ? 'active' : ''} onClick={tabClickCash}>Cash</li>
      </ul>}
        {showTabs
          ?
          <div>
            <div className="collectCash">
              <div onClick={cpClick} className={`checkBlock ${cashType === 'collect at pickup' ? 'active' : ''}`}>
                <span className="check" />
                <span>Pay at Pick-up</span>
              </div>
              <div onClick={cdClick} className={`checkBlock ${cashType === 'collect at delivery' ? 'active' : ''}`}>
                <span className="check" />
                <span>Pay at Drop-off</span>
              </div>
            </div>
            <div className="cashPaybutton">
              <button
                className={`progress-bar  ${isLoading ? ' progress-bar-striped progress-bar-animated' : ''}`}
                disabled={isLoading}
                onClick={handlePay}
              >{isLoading ? 'Processing...' : <span>Complete Booking</span>}
              </button>
            </div>
          </div>
          :
          debitTab ? 
          <div>
           <h5 className="mb-4"> Total Pay: <b>{state.currency} {state.totalSum}</b></h5>
              <TessPaymentContainer bookingData={bookingDetails} cardType={payType} paytype="debitcard"/>
            </div>
          :
          <div>
            {domain === 'UAE' ?
              <form
                method="post"
                action={PAYMENT_URL}
              >
                <Form.Row autoComplete="off">
                  <div className="col-12">
                    <Form.Group as={Col} className={errors.card_number ? 'invalid' : ''}>
                      <Form.Label>Card number</Form.Label>

                      <input
                        type="tel"
                        className="form-control cardNumber"
                        placeholder="XXXX XXXX XXXX XXXX"
                        onChange={handleInputChange}
                        onBlur={handleCardNumber}
                        id="card-number"
                      />
                      <input
                        name="card_number"
                        id="cardNumber"
                        className="hiddenInput"
                        type="hidden"
                      />
                      <span className={`icon cardPhoto ${state.issuer}`} />
                      {errors.card_number && <p className="errorMsg">{errors.card_number}</p>}
                    </Form.Group>
                  </div>
                  <div className="row">
                    <div className="col-6">

                      <Form.Group as={Col} className={errors.expiry_date ? 'invalid' : ''}>
                        <Form.Label>Expiry date</Form.Label>
                        <input
                          type="tel"
                          id="our_expiry_date"
                          className="form-control expiryDate"
                          placeholder="MM/YY"
                          onBlur={handleExpiry}
                          onChange={handleInputChange}
                          maxLength='5'

                        />
                        <input
                          name="expiry_date"
                          id="expiryDate"
                          className="hiddenInput"
                          type="hidden"
                        />
                        {errors.expiry_date && <p className="errorMsg">{errors.expiry_date}</p>}
                      </Form.Group>
                    </div>

                    <div className="col-6">
                      <Form.Group as={Col} className={errors.card_security_code ? 'invalid' : ''}>
                        <Form.Label>CVV <span className="tiptext">What&apos;s this?</span>
                          <div className="cvc-tooltip">
                            With a Visa or MasterCard the CVV/CVC is a three-digit security code printed on the back side of the card. On a American Express card, the number is four digits shown on the front of the card
                  </div>
                        </Form.Label>

                        <input
                          type="tel"
                          name="card_security_code"
                          className="form-control cvvNumber"
                          placeholder="CVV"
                          onChange={handleInputChange}
                          onBlur={handleCvv}
                          id="cvvNUmber"
                        />
                        {errors.card_security_code && <p className="errorMsg">{errors.card_security_code}</p>}
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12">
                    <Form.Group as={Col} className={holderError ? 'invalid' : ''}>
                      <Form.Label>Name on Card</Form.Label>
                      <input
                        type="text"
                        name="card_holder_name"
                        className="form-control holderName"
                        placeholder="Name"
                        onChange={handleInputChange}
                        autoComplete="off"
                        defaultValue=""
                      />
                      {holderError && <p className="errorMsg">Enter holder Name</p>}
                    </Form.Group>
                  </div>
                </Form.Row>
                <input type="hidden" name="access_code" value={ACCESS_CODE} />
                <input type="hidden" name="language" value={LANGUAGE} />
                <input type="hidden" name="merchant_extra" value={domainUrl} />
                <input type="hidden" name="merchant_extra1" value={id} />
                <input type="hidden" name="merchant_identifier" value={MERCHANT_ID} />
                <input type="hidden" name="merchant_reference" value={state.mid} />
                <input type="hidden" name="service_command" value="TOKENIZATION" />
                <input type="hidden" name="signature" value={signature} />
                <input type="hidden" name="return_url" value={returnUrl} />

                <div className="form-actions">
                  <button className={`progress-bar  ${buttonDisable ? ' progress-bar-striped progress-bar-animated' : ''}`} disabled={!!state.isLoading || validate} onClick={handleSubmit}>{state.isLoading ? 'Processing...' : <span>Pay {state.currency} {state.totalSum}</span>} </button>

                </div>
              </form>
              :
              <div>
                <TessPaymentContainer bookingData={bookingDetails} cardType={payType} paytype="credicard"/>
            </div>
            }

          </div>

        }
      </div>
    </div>
  );
};

export default PaymentContainer;
