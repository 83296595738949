/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import Cookies from 'universal-cookie';

const publicIp = require('public-ip');


const QADOMAIN = process.env.REACT_APP_QADOMAIN;
const AEDOMAIN = process.env.REACT_APP_AEDOMAIN;
const UATDOMAIN = process.env.REACT_APP_UATDOMAIN;
const POST_PURCHASE = 'https://staging-api.shypv.com/v1.0/payment/postpurchase?';
const PAYMENT_PURCHASE = 'https://staging-api.shypv.com/v1.0/payment/purchase';


let ip = '';
(async () => {
  ip = await publicIp.v4();
})();
export const bookingId = 'bookingId';
const NewPaymentWaitingContainer = (props) => {
  const history = useHistory();
  const [paymentFail, setPaymentFail] = useState(false);
  const [bkId, setBkId] = useState('');
  const cookies = new Cookies();
  let domainURL = "";
  if (window.location.host === QADOMAIN) {
    domainURL = QADOMAIN;
  }
  else if (window.location.host === AEDOMAIN) {
    domainURL = AEDOMAIN;
  }
  else {
    domainURL = UATDOMAIN;
  }
  const handleRetry = () => {
    history.push({ pathname: `mobile-payment/${bkId}` });
  };
  const purchaseReq = (reqData, result) => {
    const redirect = "/payment-success";
    const reference = result.bkngid;
    const domain = domainURL;
    const buildURI = "redirect=" + redirect + "&reference=" + reference + "&domain=" + domain;
    const url = POST_PURCHASE + buildURI;
    const purchasedRequest = {
      amount: result.prc,
      currency: 'AED',
      customerEmail: result.email,
      token: reqData.token,
      bookingReference: result.bkngid,
      bookingStatus: 'Confirmed',
      deviceFingerPrint: reqData.deviceFingerPrint,
      returnUrl: url,
      customerIP: ip,
      merchantRef: reqData.bookingReference,
      newPayfort: true,
    };
    return fetch(PAYMENT_PURCHASE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        token: '0efc537c9b5147f3b0dd427ed4be6eca',
      },
      body: JSON.stringify(purchasedRequest),
    })
      .then(response => response.json())
      .then((data) => {
        if (data.success) {
          if (data.data.responseCode === '20064' || data.data.responseCode === '14000') {
            const anchor = document.createElement('a');
            anchor.href = data.data.url3d;
            anchor.click();
          } else {
            setPaymentFail(true);
          }
        } else {
          setPaymentFail(true);
        }
      })
      .catch((error) => {
        console.error('ERROR: ', error);
      });
  };
  const fetchBookingDetails = async (reqData) => {
    const url = `https://staging-api.shypv.com/v1.0/task/booking_details?booking_id=${reqData.merchantExtra1}`
    await fetch(url).then((response) => response.json())
      .then(res => {
        if (res.success) {
          purchaseReq(reqData, res.data);
        }
      });
  };
  useEffect(() => {
    setBkId(localStorage.getItem(bookingId));
    //setBkId(cookies.get('bookingId'));
    var params = {};
    if (props.location.search) {
      var parts = props.location.search.substring(1).split('&');
      for (var i = 0; i < parts.length; i++) {
        var nv = parts[i].split('=');
        if (!nv[0]) continue;
        params[nv[0]] = nv[1] || true;
      }
    }
    console.log(params);
    if (params) {
      let command = params.service_command ? params.service_command : params.command;
      if (command === 'TOKENIZATION' && params.response_code === '18000') {
        const mi = document.createElement('input');
        mi.setAttribute('type', 'hidden');
        mi.setAttribute('id', 'device_fingerprint');
        mi.setAttribute('name', 'device_fingerprint');
        document.body.appendChild(mi);

        const script = document.createElement('script');

        script.src = 'https://mpsnare.iesnare.com/snare.js?onload=callbackname';

        document.body.appendChild(script);
        script.onload = () => {
          let fingerPrint = null;
          if (document.getElementById('device_fingerprint').value) {
            fingerPrint = document.getElementById('device_fingerprint').value;
          }
          const reqObj = {
            token: params.token_name,
            bookingReference: params.merchant_reference,
            bookingStatus: 'Confirmed',
            deviceFingerPrint: fingerPrint,
            merchantExtra1: params.merchant_extra1,
          };
          console.log(reqObj);
          fetchBookingDetails(reqObj);
        };
      } else if(params.responsecode === '000') {
        history.push('/payment-success')
      } else {
        setPaymentFail(true);
      }
    }
    
  }, []);
  let rows;
  if (paymentFail) {
    rows = (
      <div className="paymentRedirect" >
      <div key="Payment" className="paymentBody">
          <div className="App-payment paymentForm">
            {paymentFail && <div className="paymnetFailBlock">
              <h3>Payment Failed! <br />  </h3>
              <button variant="primary" className="btn-primary btn-outline" onClick={handleRetry}>
                Try again
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
  } else {
    rows = (<div className="paymentRedirect" >
      <div key="Payment" className="paymentBody">
          <div className="App-payment paymentForm">
            <div className="flex align-items-center loading"><Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner> Verifying Details...</div>
          </div>
        </div>
    </div >);
  }
  return rows;
};



export default NewPaymentWaitingContainer;
